<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <validation-observer v-slot="{ invalid }">
      <div class="text-box-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
        <popup
          :buttons="buttons"
          :invalid="lookOnlyFlg === 1"
          :disabledFlg="waitFlg || invalid || checkDuplicate(value)"
          @cancel="checkDiff"
          @decision="$emit('decision')"
          @click-close-mark="checkDiff"
        >
          <template v-slot:content>
            <div class="content">
              <slot></slot>
              <validation-provider
                class="validation-provider"
                :rules="{ requiredRule: true, notSurrogatePair: true }"
                v-slot="{ errors }"
              >
                <div class="label">必須</div>
                <base-text-box
                  class="text-box"
                  :value="value"
                  :maxlength="15"
                  :styles="{ width: '240px' }"
                  @input="value => $emit('input', value)"
                />
                <div class="error" data-test="err">{{ errors[0] }}</div>
                <div
                  v-if="checkDuplicate(value)"
                  class="error"
                  data-test="exist-error-message"
                >
                  既に使用されています。
                </div>
              </validation-provider>
            </div>
          </template>
        </popup>
      </div>
      <announce-popup
        v-if="popup.show"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        :layerNumber="2"
        @cancel="popup.show = false"
        @decision="$emit('close')"
        >{{ popup.message }}</announce-popup
      >
    </validation-observer>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'

export default {
  name: 'TextBoxPopup',

  components: {
    FocusTrap,
    Popup,
    BaseTextBox,
    ValidationObserver,
    ValidationProvider,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    value: { type: String, default: '' },
    buttons: { type: Array },
    names: { type: Array },
    waitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      initialValue: '',
      popup: {
        show: false,
        type: 'alert',
        title: '確認',
        buttons: ['キャンセル', '閉じる'],
        message: '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      }
    }
  },

  computed: {
    ...mapGetters({ lookOnlyFlg: 'auth/lookOnlyFlg' }),
    diffFlg() {
      return this.initialValue !== this.value
    }
  },

  created() {
    this.setInitialValue()
  },

  methods: {
    checkDuplicate(value) {
      if (value === '') return false
      return this.names.includes(value)
    },
    checkDiff() {
      this.diffFlg ? (this.popup.show = true) : this.$emit('close')
    },
    setInitialValue() {
      this.initialValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.text-box-popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    white-space: pre-line;
    @include validate-message();
    > .validation-provider {
      > .label {
        margin-top: 30px;
        margin-left: 125px;
        font-size: 13px;
        color: #{$tomato};
        text-align: left;
      }
      > .error {
        margin-left: 125px;
      }
    }
  }
}
</style>
