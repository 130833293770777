<template>
  <div class="line-graph-table-row" :class="{ even: (index + 1) % 2 === 0 }">
    <div class="header" data-test="date">{{ date }}</div>
    <div
      class="data"
      v-for="n in tableData.length - 1"
      :key="n"
      :data-test="`data${n}`"
    >
      {{ tableData[n][index].toLocaleString() }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'lineGraphTableRow',

  props: {
    index: { type: Number, default: 0 },
    date: { type: String, default: '' },
    tableData: { type: Array }
  }
}
</script>

<style lang="scss" scoped>
.line-graph-table-row {
  border-top: 1px solid #{$light-grey};
  height: 36px;
  display: flex;
  &.even {
    background-color: #{$white_f7};
  }
  > .header {
    width: 100px;
    min-width: 100px;
  }
  > .data {
    width: calc(100% - 100px);
    border-left: 1px solid #{$light-grey};
  }
}
</style>
