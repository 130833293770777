<template>
  <div class="base-select-box">
    <div class="select-wrapper" :class="{ disabled: disabled }">
      <select
        class="select-box"
        :class="{ 'disabled-color-white': disabledColorWhite }"
        data-test="select-box"
        data-e2e="select-box"
        :value="dispValue"
        :disabled="disabled"
        :style="styles"
        @change="selectValue"
        @blur="$emit('blur')"
      >
        <option
          v-for="(datum, index) in dispSelectData"
          :value="datum"
          :key="index"
        >
          {{ datum }}
        </option>
        <option value="" class="placeholder" disabled selected>{{
          placeholder
        }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectBoxNoId',

  props: {
    value: { type: String, default: '' },
    /*
      SelectDataに
      [ ['飼主', 'owner'], ['患者', 'patient']]
      のような二次元配列を渡すと表示は飼主・患者となりデータはowner・patientになります。
    */
    selectData: {
      type: Array,
      required: true,
      validator: arr => {
        return arr.every(v => {
          return typeof v[0] === 'string' && typeof v[1] === 'string'
        })
      }
    },
    disabled: { type: Boolean, default: false },
    disabledColorWhite: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    placeholder: { type: String, default: '' }
  },

  computed: {
    dispValue() {
      const map = new Map(this.selectData.map(v => [v[1], v[0]]))
      return map.get(this.value)
    },
    dispSelectData() {
      return this.selectData.map(v => v[0])
    }
  },

  methods: {
    selectValue(e) {
      const map = new Map(this.selectData.map(v => [v[0], v[1]]))
      this.$emit('input', map.get(e.target.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.base-select-box {
  > .select-wrapper {
    display: inline-block;
    position: relative;
    background-color: #{$white};
    border-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #{$pumpkin};
      border-right: solid 2px #{$pumpkin};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    &.disabled {
      &:after {
        border-bottom: solid 2px #{$brown-gray};
        border-right: solid 2px #{$brown-gray};
      }
    }
    > .select-box {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      position: relative;
      z-index: 1;
      height: 33px;
      width: 100%;
      padding: 0 26px 0 8px;
      background: transparent;
      border: solid 2px #{$light-grey};
      border-radius: 5px;
      font-size: 13px;
      > .option-default-none {
        display: none;
      }
      > .placeholder {
        display: none;
      }
      &:disabled {
        pointer-events: none;
        background-color: #{$light-grey};
      }
      &.disabled-color-white {
        &:disabled {
          opacity: 1;
          background-color: #{$white};
          color: #{$greyish-brown};
        }
      }
    }
    > .select-box::-ms-expand {
      display: none;
    }
  }
}
</style>
