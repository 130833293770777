<template>
  <div class="analytics-setting">
    <div class="wrap">
      <div class="title">集計</div>
      <div class="contents">
        <div class="tab-area">
          <div class="tab-title-bar">
            <base-setting-tab-title
              v-for="titleObj in tabTitles"
              :key="titleObj.key"
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
        <div class="content-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'

export default {
  name: 'AnalyticsSetting',

  components: {
    BaseSettingTabTitle
  },

  data() {
    return {
      tabTitles: [
        {
          key: 1,
          title: '折れ線グラフ',
          link: { name: 'line-graph' },
          names: ['line-graph']
        },
        {
          key: 2,
          title: '入金状況',
          link: { name: 'payment-aggregations' },
          names: ['payment-aggregations']
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.analytics-setting {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    padding: 24px 48px;
    box-sizing: border-box;
    display: inline-block;
    min-width: 100%;
    > .title {
      font-size: 20px;
      font-weight: bold;
    }
    > .contents {
      margin-top: 20px;
      min-width: 614px;
      > .tab-area {
        margin-top: 0px;
        > .tab-title-bar {
          display: flex;
          flex-direction: row;
          height: 26px;
          margin-top: 20px;
          padding-bottom: -4px;
          border-bottom: 1px solid;
          border-color: #{$light-grey};
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
