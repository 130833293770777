<template>
  <div class="payment-aggregations">
    <base-loading :waitFlg="waitFlg" />
    <div class="search">
      <search-area
        :periodFlg="true"
        :periodLabel="'集計期間'"
        :periodClearFlg="false"
        :defaultStartDate="new Date()"
        :defaultEndDate="new Date()"
        :maxStartDate="new Date()"
        :maxEndDate="new Date()"
        :searchButtonFlg="true"
        :searchButtonLabel="'集計表示'"
        :waitFlg="waitFlg"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @search="aggregatePayments"
      />
    </div>
    <div class="aggregate-result" v-if="Object.keys(datum).length > 0">
      <div class="download-button">
        <base-button-small-orange
          @click="download"
          :disabled="datum.aggregatedPayments.length === 0"
        >
          ダウンロード
        </base-button-small-orange>
      </div>
      <div class="area total">
        <div class="label aggregated-date-time">集計日時</div>
        <div class="value aggregated-date-time">
          {{ formatAggregatedDateTime }}
        </div>
        <div class="label last-updated-date-time">会計最終編集日時</div>
        <div class="value last-updated-date-time">
          {{ formatPaymentLastUpdatedAtStr }}
        </div>
        <div class="label total-count">件数</div>
        <div class="value total-count">
          {{ datum.aggregatedPayments.length }}
        </div>
        <div class="label total-amount-of-sales">
          <div>売上高(税込)計</div>
          <div>/税計 （￥）</div>
        </div>
        <div class="value total-amount-of-sales">
          <div class="value-item total-burden-amount">
            {{ `￥${datum.aggregatedTotalBurdenAmount.toLocaleString()}` }}
          </div>
          <div class="value-item consumption-tax">
            {{ `/￥${datum.aggregatedTotalConsumptionTax.toLocaleString()}` }}
          </div>
        </div>
        <div class="label total-inside-unpaid-amount">*内未収金（￥）</div>
        <div class="value total-inside-unpaid-amount">
          {{ `￥${datum.aggregatedTotalInsideUnpaidAmount.toLocaleString()}` }}
        </div>
      </div>
      <div class="area unpaid-amount-message">
        *未収金計-未収金戻入計となります。
      </div>
      <div class="area table">
        <payment-aggregation-list-table
          :aggregatedPayments="datum.aggregatedPayments"
        />
      </div>
      <div class="area total-table">
        <div class="line header">
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell cash-minus-change">現金収入計（￥）</div>
          <div class="cell card">カード収入計（￥）</div>
          <div class="cell unpaid">未収金計（￥）</div>
          <div class="cell insurance-price-anicom">アニコム損保計（￥）</div>
          <div class="cell insurance-price-ipet">アイペット損保計（￥）</div>
          <div class="cell total-amount-of-sales">
            <div>売上高(税込)計</div>
            <div>/税計 （￥）</div>
          </div>
          <div class="cell reversal-of-unpaid-amount">未収金戻入計（￥）</div>
        </div>
        <div class="line body">
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell space" />
          <div class="cell cash-minus-change">
            {{ `￥${datum.aggregatedTotalCashMinusChange.toLocaleString()}` }}
          </div>
          <div class="cell card">
            {{ `￥${datum.aggregatedTotalCard.toLocaleString()}` }}
          </div>
          <div class="cell unpaid">
            {{ `￥${datum.aggregatedTotalUnpaidAmount.toLocaleString()}` }}
          </div>
          <div class="cell insurance-price-anicom">
            {{
              `￥${datum.aggregatedTotalInsurancePriceAnicom.toLocaleString()}`
            }}
          </div>
          <div class="cell insurance-price-ipet">
            {{
              `￥${datum.aggregatedTotalInsurancePriceIpet.toLocaleString()}`
            }}
          </div>
          <div class="cell total-amount-of-sales">
            <div class="cell-item total-burden-amount">
              {{ `￥${datum.aggregatedTotalBurdenAmount.toLocaleString()}` }}
            </div>
            <div class="cell-item consumption-tax">
              {{ `/￥${datum.aggregatedTotalConsumptionTax.toLocaleString()}` }}
            </div>
          </div>
          <div class="cell reversal-of-unpaid-amount">
            {{
              `￥${datum.aggregatedTotalReversalOfUnpaidAmount.toLocaleString()}`
            }}
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      @close="popupFlg = false"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import PaymentAggregationListTable from '@/components/parts/organisms/PaymentAggregationListTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { getAxiosObjectForAnalytics } from '@/utils/library'
import { downloadCsv } from '@/utils/csv'
import moment from 'moment'

export default {
  name: 'PaymentAggregations',
  components: {
    SearchArea,
    BaseButtonSmallOrange,
    BaseLoading,
    PaymentAggregationListTable,
    AnnouncePopup
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      datum: {},
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false
    }
  },
  computed: {
    formatAggregatedDateTime() {
      if (Object.keys(this.datum).length > 0) {
        const aggregatedDateTimeMoment = moment(
          this.datum.aggregatedDateTime,
          'YYYYMMDDHHmm'
        )
        return (
          aggregatedDateTimeMoment.format('YYYY年MM月DD日') +
          '\n' +
          aggregatedDateTimeMoment.format('HH時mm分')
        )
      } else {
        return ''
      }
    },
    formatPaymentLastUpdatedAtStr() {
      if (
        Object.keys(this.datum).length > 0 &&
        this.datum.paymentLastUpdatedAtStr.length > 0
      ) {
        const paymentLastUpdatedAtStrMoment = moment(
          this.datum.paymentLastUpdatedAtStr,
          'YYYYMMDDHHmm'
        )
        return (
          paymentLastUpdatedAtStrMoment.format('YYYY年MM月DD日') +
          '\n' +
          paymentLastUpdatedAtStrMoment.format('HH時mm分')
        )
      } else {
        return ''
      }
    }
  },
  methods: {
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    async aggregatePayments() {
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObjectForAnalytics()
        const res = await axiosObject.get('/analytics/payment-aggregations', {
          params: { startDate: this.startDate, endDate: this.endDate }
        })
        this.datum = res.data.aggregatedDatum
        this.waitFlg = false
      } catch (err) {
        this.waitFlg = false
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          '集計に失敗しました。\n暫く待ってから再度お試し下さい。'
        this.popupFlg = true
      }
    },
    createCsv() {
      const headerStr =
        '診療費発生日,入金日,飼主ID,患者ID,飼主名,患者名,現金収入,カード収入,未収金,アニコム損保,アイペット損保,売上高(税込),税,未収金戻入\n'
      const bodyStr = this.datum.aggregatedPayments.reduce((str, v) => {
        return (
          str +
          `${moment(v.medicalTreatmentDay, 'YYYYMMDD').format(
            'YYYY/MM/DD'
          )},${moment(v.payDateTime, 'YYYYMMDDHHmmss').format('YYYY/MM/DD')},${
            v.ownerShowId
          },${v.patientShowId},${v.ownerFullName},${v.patientName},${
            v.totalCashMinusChange
          },${v.totalCard},${v.unpaidAmount},${v.insurancePriceAnicom},${
            v.insurancePriceIpet
          },${v.burdenAmount},${v.consumptionTax},${v.reversalOfUnpaidAmount}\n`
        )
      }, '')
      const totalHeaderStr =
        ',,,,,,現金収入計,カード収入計,未収金計,アニコム損保計,アイペット損保計,売上高(税込)計,税計,未収金戻入計,内未収金,集計日時,会計最終編集日時\n'
      const totalBodyStr =
        ',,,,,,' +
        this.datum.aggregatedTotalCashMinusChange +
        ',' +
        this.datum.aggregatedTotalCard +
        ',' +
        this.datum.aggregatedTotalUnpaidAmount +
        ',' +
        this.datum.aggregatedTotalInsurancePriceAnicom +
        ',' +
        this.datum.aggregatedTotalInsurancePriceIpet +
        ',' +
        this.datum.aggregatedTotalBurdenAmount +
        ',' +
        this.datum.aggregatedTotalConsumptionTax +
        ',' +
        this.datum.aggregatedTotalReversalOfUnpaidAmount +
        ',' +
        this.datum.aggregatedTotalInsideUnpaidAmount +
        ',' +
        moment(this.datum.aggregatedDateTime, 'YYYYMMDDHHmmss').format(
          'YYYY/MM/DD HH:mm:ss'
        ) +
        ',' +
        moment(this.datum.paymentLastUpdatedAtStr, 'YYYYMMDDHHmmss').format(
          'YYYY/MM/DD HH:mm:ss'
        )
      const csvStr = headerStr + bodyStr + totalHeaderStr + totalBodyStr
      const fileName = `csv_aggregate_${this.startDate}_${this.endDate}`
      return { csvStr, fileName }
    },
    download() {
      const { csvStr, fileName } = this.createCsv()
      downloadCsv(csvStr, fileName)
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-aggregations {
  > .search {
    margin-top: 40px;
    min-width: 1324px;
  }
  > .aggregate-result {
    > .download-button {
      margin: 30px 20px 0 auto;
      width: 135px;
    }
    > .total {
      margin-top: 30px;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 55px;
      border: solid 1px #{$light-grey};
      font-size: 13px;
      text-align: center;
      > .label {
        width: 10%;
        background-color: #{$pale};
        border-left: solid 1px #{$light-grey};
        border-right: solid 1px #{$light-grey};
        height: 55px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        &.aggregated-date-time {
          border-left: none;
        }
      }
      > .value {
        width: 10%;
        word-wrap: break-word;
        &.aggregated-date-time {
          white-space: pre-line;
        }
        &.last-updated-date-time {
          white-space: pre-line;
        }
        &.total-inside-unpaid-amount {
          border-right: none;
        }
      }
    }
    > .unpaid-amount-message {
      margin-top: 5px;
      text-align: right;
      font-size: 15px;
    }
    > .table {
      margin-top: 30px;
    }
    > .total-table {
      margin-top: 20px;
      width: 100%;
      border: solid 1px #{$light-grey};
      font-size: 13px;
      text-align: center;
      > .line {
        display: flex;
        min-height: 55px;
        align-items: center;
        &.header {
          background-color: #{$pale};
          border-bottom: solid 1px #{$light-grey};
          > .cell {
            height: 55px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-left: solid 1px #{$light-grey};
            &.space {
              border-left: none;
            }
          }
        }
        > .cell {
          box-sizing: border-box;
          flex: 1;
          padding: 0 15px;
          word-wrap: break-word;
          text-align: right;
          &.space {
            min-width: 120px;
          }
          &.cash-minus-change {
            min-width: 100px;
          }
          &.card {
            min-width: 100px;
          }
          &.unpaid {
            min-width: 100px;
          }
          &.insurance-price-anicom {
            min-width: 100px;
          }
          &.insurance-price-ipet {
            min-width: 120px;
          }
          &.total-amount-of-sales {
            min-width: 120px;
            word-break: break-all;
          }
          &.reversal-of-unpaid-amount {
            min-width: 100px;
          }
        }
      }
    }
  }
}
</style>
