var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"analytics-menu"},[_c('validation-observer',{staticClass:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"set-up-option wrapper"},[_c('div',{staticClass:"button-area"},[_c('div',{staticClass:"label"},[_vm._v("集計条件")]),(_vm.lookOnlyFlg === 0)?_c('base-button-small-orange',{staticClass:"save-button",attrs:{"styles":{ width: '50px' },"disabled":invalid,"data-test":"save-button-analytics"},on:{"click":_vm.openTextBoxPopup}},[_vm._v("保存")]):_vm._e(),(_vm.lookOnlyFlg === 0)?_c('base-button-small-red',{staticClass:"del-button",attrs:{"styles":{ width: '50px' },"disabled":_vm.selectAnalyticsId === 0,"data-test":"delete-button-analytics"},on:{"click":_vm.openDeletePopup}},[_vm._v("削除")]):_vm._e()],1),_c('div',{staticClass:"input-area"},[_c('base-select-box',{staticClass:"select-box",attrs:{"styles":{ width: '240px' },"selectData":_vm.analyticsSelectData,"data-test":"select-box-analytics"},on:{"input":function($event){return _vm.setAnalytics(_vm.selectAnalyticsId)}},model:{value:(_vm.selectAnalyticsId),callback:function ($$v) {_vm.selectAnalyticsId=$$v},expression:"selectAnalyticsId"}}),_c('base-button-small-white',{attrs:{"styles":{ width: '81px', marginLeft: '10px' },"data-test":"clear-button-analytics"},on:{"click":function($event){return _vm.setAnalytics(0)}}},[_vm._v("クリア")])],1)]),_c('div',{staticClass:"period wrapper"},[_c('div',{staticClass:"label"},[_vm._v("期間")]),_c('div',{staticClass:"input-area"},[_c('v-date-picker',{attrs:{"input-props":{
            class: 'v-date-picker start',
            readonly: true
          },"min-date":new Date(2020, 0, 1),"max-date":_vm.maxDate,"is-required":"","data-test":"v-date-picker-start"},on:{"input":function (date) { return (_vm.conditions.startDate = _vm.moment(date).format('YYYYMMDD')); }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('div',{staticClass:"wavy-line"},[_vm._v("～")]),_c('v-date-picker',{attrs:{"input-props":{
            class: 'v-date-picker end',
            readonly: true
          },"min-date":_vm.minDate,"maxDate":_vm.nextYear,"is-required":"","data-test":"v-date-picker-end"},on:{"input":function (date) { return (_vm.conditions.endDate = _vm.moment(date).format('YYYYMMDD')); }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)]),_c('div',{staticClass:"time-axis wrapper"},[_c('div',{staticClass:"label"},[_vm._v("単位")]),_c('div',{staticClass:"input-area"},_vm._l((_vm.timeAxisOptions),function(option){return _c('base-radio-button',{key:option.id,attrs:{"option":option,"styles":{ marginRight: '15px', fontSize: '13px' },"inputStyles":{ paddingLeft: '15px' }},model:{value:(_vm.conditions.timeAxis),callback:function ($$v) {_vm.$set(_vm.conditions, "timeAxis", $$v)},expression:"conditions.timeAxis"}})}),1)]),_c('div',{staticClass:"new-or-total wrapper"},[_c('div',{staticClass:"label"},[_vm._v("新規・累計")]),_c('div',{staticClass:"input-area"},_vm._l((_vm.newOrTotalOptions),function(option){return _c('base-radio-button',{key:option.id,attrs:{"option":option,"styles":{ marginRight: '15px', fontSize: '13px' },"inputStyles":{ paddingLeft: '15px' }},model:{value:(_vm.conditions.newOrTotal),callback:function ($$v) {_vm.$set(_vm.conditions, "newOrTotal", $$v)},expression:"conditions.newOrTotal"}})}),1)]),_vm._l((_vm.conditions.targets),function(target,tIndex){return _c('div',{key:target.key,staticClass:"target wrapper"},[_c('div',{staticClass:"label-area"},[_c('div',{staticClass:"label",style:(_vm.targetLabelStyle(tIndex))},[_vm._v(" 集計対象 "+_vm._s(tIndex + 1)+" ")]),_c('validation-provider',{staticClass:"validation-provider",attrs:{"rules":{ notSurrogatePair: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-text-box',{attrs:{"maxlength":10,"placeholder":'集計対象名',"styles":{ width: '217px', fontSize: '15px' },"data-test":"label-input-box"},model:{value:(target.label),callback:function ($$v) {_vm.$set(target, "label", $$v)},expression:"target.label"}}),_c('div',{staticClass:"error",attrs:{"data-test":"err"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"target-select-box-area"},[_c('validation-provider',{attrs:{"rules":{ requiredRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-select-box-no-id',{attrs:{"selectData":_vm.targetsSelectData,"styles":{ width: '295px' },"data-test":"select-box-target"},model:{value:(target.name),callback:function ($$v) {_vm.$set(target, "name", $$v)},expression:"target.name"}}),_c('div',{staticClass:"error",attrs:{"data-test":"err"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(tIndex !== 0)?_c('base-button-trash',{staticClass:"trash-button",on:{"click":function($event){return _vm.delTarget(target.key)}}}):_vm._e()],1),(target.name)?_c('div',{staticClass:"filter-area"},[_c('div',{staticClass:"label"},[_vm._v("絞り込み")]),_vm._l((target.filters),function(filter,fIndex){return _c('div',{key:filter.key,staticClass:"filter-input-area"},[_c('validation-provider',{staticClass:"validation-provider",attrs:{"rules":{ requiredRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-select-box-no-id',{staticClass:"filter-names-select-box",attrs:{"selectData":_vm.filterNamesSelectData(target.filters, fIndex, target.name),"styles":{ width: '90px' },"data-test":"filter-names-select-box"},on:{"input":function($event){filter.id = null}},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}}),_c('div',{staticClass:"error adjust",attrs:{"data-test":"err"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(filter.name !== 'breed')?_c('validation-provider',{staticClass:"validation-provider",attrs:{"rules":{ requiredRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-select-box',{staticClass:"filters-select-box",attrs:{"selectData":_vm.filtersSelectData(filter.name),"styles":{ width: '160px' },"data-test":"filters-select-box"},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}}),_c('div',{staticClass:"error adjust2",attrs:{"data-test":"err"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(filter.name === 'breed')?_c('validation-provider',{staticClass:"validation-provider",attrs:{"rules":{ notSurrogatePair: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('base-text-box',{staticClass:"text-box",attrs:{"styles":{ width: '160px' },"maxlength":50},model:{value:(filter.text),callback:function ($$v) {_vm.$set(filter, "text", $$v)},expression:"filter.text"}}),_c('div',{staticClass:"error adjust2",attrs:{"data-test":"err"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('base-button-trash',{staticClass:"trash-button",attrs:{"data-test":"trash-button-filter"},on:{"click":function($event){return _vm.delFilter(tIndex, filter.key)}}})],1)}),_c('base-button-plus',{staticClass:"plus-button",attrs:{"styles":{ width: '35px' },"disabled":target.filters.length >= 6 ||
              (target.name === 'newOwner' && target.filters.length >= 3),"data-test":"filter-plus-button"},on:{"click":function($event){return _vm.addFilter(tIndex)}}})],2):_vm._e()])}),_c('div',{staticClass:"buttons wrapper"},[_c('base-button-plus',{staticClass:"add-button",attrs:{"styles":{ width: '140px' },"text":'集計対象追加',"disabled":_vm.conditions.targets.length >= 5,"data-test":"plus-button-target"},on:{"click":_vm.addTarget}}),_c('base-button-medium-orange',{staticClass:"start-button",attrs:{"disabled":_vm.analyticsWaitFlg || invalid,"data-test":"start-analytics"},on:{"click":_vm.startAnalytics}},[_vm._v("集計開始")])],1)]}}])}),(_vm.popup.alertFlg)?_c('announce-popup',{attrs:{"type":_vm.popup.type,"title":_vm.popup.title,"buttons":_vm.popup.buttons,"disabled":_vm.waitFlg,"layerNumber":3},on:{"cancel":function($event){_vm.popup.alertFlg = false},"decision":_vm.popup.decision,"close":_vm.closePopup}},[_vm._v(_vm._s(_vm.popup.message))]):_vm._e(),(_vm.textBoxPopup.alertFlg)?_c('text-box-popup',{ref:"textBoxPopup",attrs:{"value":_vm.textBoxPopup.value,"buttons":_vm.textBoxPopup.buttons,"names":_vm.textBoxPopup.names,"waitFlg":_vm.waitFlg},on:{"input":function (value) { return (_vm.textBoxPopup.value = value); },"close":function($event){_vm.textBoxPopup.alertFlg = false},"decision":_vm.saveConditions}},[_vm._v(_vm._s(_vm.textBoxPopup.message))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }