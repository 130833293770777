<template>
  <div class="payment-list-table">
    <div class="table-header">
      <div class="cell date">診療発生日</div>
      <div class="cell pay-date-time">入金日</div>
      <div class="cell owner">
        <div>飼主ID</div>
        <div>飼主名</div>
      </div>
      <div class="cell patient">
        <div>患者ID</div>
        <div>患者名</div>
      </div>
      <div class="cell cash-minus-change">現金（￥）</div>
      <div class="cell card">カード決済（￥）</div>
      <div class="cell unpaid">未収金（￥）</div>
      <div class="cell insurance-price-anicom">アニコム損保（￥）</div>
      <div class="cell insurance-price-ipet">アイペット損保（￥）</div>
      <div class="cell burden-amount">
        <div>売上高(税込)</div>
        <div>/税 （￥）</div>
      </div>
      <div class="cell reversal-of-unpaid-amount">未収金戻入（￥）</div>
    </div>
    <div v-if="displayData.length > 0" class="table-body">
      <div class="row" v-for="datum in displayData" :key="datum.key">
        <div class="cell date">{{ datum.medicalTreatmentDay }}</div>
        <div class="cell pay-date">{{ datum.payDate }}</div>
        <div class="cell owner">
          <div>{{ datum.ownerShowId }}</div>
          <div>{{ datum.ownerFullName }}</div>
        </div>
        <div class="cell patient">
          <div>{{ datum.patientShowId }}</div>
          <div>{{ datum.patientName }}</div>
        </div>
        <div class="cell cash-minus-change right-align">
          {{ datum.totalCashMinusChange }}
        </div>
        <div class="cell card right-align">{{ datum.totalCard }}</div>
        <div class="cell unpaid right-align">{{ datum.unpaidAmount }}</div>
        <div class="cell insurance-price-anicom right-align">
          {{ datum.insurancePriceAnicom }}
        </div>
        <div class="cell insurance-price-ipet right-align">
          {{ datum.insurancePriceIpet }}
        </div>
        <div class="cell burden-amount right-align">
          <div>{{ datum.burdenAmount }}</div>
          <div>/{{ datum.consumptionTax }}</div>
        </div>
        <div class="cell reversal-of-unpaid-amount right-align">
          {{ datum.reversalOfUnpaidAmount }}
        </div>
      </div>
    </div>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PaymentAggregationListTable',

  props: {
    aggregatedPayments: {
      type: Array
    }
  },

  computed: {
    displayData() {
      return this.aggregatedPayments.map((v, i) => {
        return {
          ...v,
          key: i + 1,
          medicalTreatmentDay: moment(v.medicalTreatmentDay, 'YYYYMMDD').format(
            'YYYY年MM月DD日'
          ),
          payDate: moment(v.payDateTime, 'YYYYMMDDHHmmss').format(
            'YYYY年MM月DD日'
          ),
          burdenAmount: `￥${v.burdenAmount.toLocaleString()}`,
          consumptionTax: `￥${v.consumptionTax.toLocaleString()}`,
          unpaidAmount: `￥${v.unpaidAmount.toLocaleString()}`,
          totalCashMinusChange: `￥${v.totalCashMinusChange.toLocaleString()}`,
          totalCard: `￥${v.totalCard.toLocaleString()}`,
          insurancePriceAnicom: `￥${v.insurancePriceAnicom.toLocaleString()}`,
          insurancePriceIpet: `￥${v.insurancePriceIpet.toLocaleString()}`,
          reversalOfUnpaidAmount: `￥${v.reversalOfUnpaidAmount.toLocaleString()}`
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-list-table {
  > .table-header {
    font-size: 13px;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    > .cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      flex: 1;
      text-align: center;
      &.cash-minus-change {
        min-width: 100px;
      }
      &.card {
        min-width: 100px;
      }
      &.unpaid {
        min-width: 100px;
      }
      &.insurance-price-anicom {
        min-width: 100px;
      }
      &.reversal-of-unpaid-amount {
        min-width: 100px;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    font-size: 13px;
    box-sizing: border-box;
    border: solid #{$light-grey};
    border-bottom: 0px;
    border-width: 0px 1px 1px 1px;
    .row {
      display: flex;
      min-height: 55px;
      border-bottom: 1px solid #{$light-grey};
      align-items: center;
      &:nth-child(even) {
        background-color: #{$white_f7};
      }
      > .cell {
        box-sizing: border-box;
        flex: 1;
        text-align: center;
        min-width: 120px;
        padding: 0 15px;
        word-wrap: break-word;
        &.date {
          padding: 0 10px;
        }
        &.pay-date {
          padding: 0 10px;
        }
        &.cash-minus-change {
          min-width: 100px;
        }
        &.card {
          min-width: 100px;
        }
        &.unpaid {
          min-width: 100px;
        }
        &.insurance-price-anicom {
          min-width: 100px;
        }
        &.burden-amount {
          word-break: break-all;
        }
        &.reversal-of-unpaid-amount {
          min-width: 100px;
        }
        &.right-align {
          text-align: right;
        }
      }
    }
  }
}
</style>
