import { render, staticRenderFns } from "./TextBoxPopup.vue?vue&type=template&id=d5f19386&scoped=true&"
import script from "./TextBoxPopup.vue?vue&type=script&lang=js&"
export * from "./TextBoxPopup.vue?vue&type=script&lang=js&"
import style0 from "./TextBoxPopup.vue?vue&type=style&index=0&id=d5f19386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5f19386",
  null
  
)

export default component.exports