<template>
  <div class="line-graph">
    <base-loading :waitFlg="waitFlg" />
    <div class="body">
      <div class="menu-area">
        <line-graph-menu :analyticsWaitFlg="waitFlg" @click="startAnalytics" />
      </div>
      <div class="graph-area">
        <base-button-small-orange
          class="download-button"
          v-if="backData !== null"
          @click="download"
          >ダウンロード</base-button-small-orange
        >
        <div id="aaaaa" class="graph" ref="chart"></div>
        <line-graph-table
          v-if="tableData.length > 0"
          :names="backData.names"
          :tableData="tableData"
        />
      </div>
    </div>
    <unsaved-leave-popup :layerNumber="2" />
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      @close="popupFlg = false"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import LineGraphMenu from '@/components/parts/organisms/LineGraphMenu'
import LineGraphTable from '@/components/parts/organisms/LineGraphTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import bb, { line, zoom } from 'billboard.js'
import 'billboard.js/dist/theme/insight.css'
import { getAxiosObjectForAnalytics } from '@/utils/library'
import { downloadCsv } from '@/utils/csv'
import _ from 'lodash'

export default {
  name: 'LineGraph',

  components: {
    AnnouncePopup,
    BaseButtonSmallOrange,
    BaseLoading,
    LineGraphMenu,
    LineGraphTable,
    UnsavedLeavePopup
  },

  data() {
    return {
      backData: null,
      tableData: [],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false
    }
  },

  methods: {
    async startAnalytics(conditions) {
      try {
        this.waitFlg = true
        this.backData = null
        this.tableData = []
        const axiosObject = getAxiosObjectForAnalytics()
        const res = await axiosObject.get('/analytics/line-graph', {
          params: { conditions }
        })
        this.backData = res.data.data
        this.setLineGraph()
        this.createTableData()
        this.waitFlg = false
      } catch {
        this.waitFlg = false
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          '集計に失敗しました。\n暫く待ってから再度お試し下さい。'
        this.popupFlg = true
      }
    },
    async setLineGraph() {
      const backData = this.backData
      await this.$nextTick()
      bb.generate({
        bindto: this.$refs.chart,
        data: {
          columns: backData.columns,
          types: {
            data1: line(),
            data2: line(),
            data3: line(),
            data4: line(),
            data5: line()
          },
          colors: {
            data1: 'red',
            data2: 'green',
            data3: 'blue',
            data4: 'orange',
            data5: 'purple'
          },
          axes: backData.axes,
          names: backData.names
        },
        axis: {
          x: {
            type: 'indexed',
            tick: {
              format: function(x) {
                return backData.timeAxis === 'years'
                  ? backData.days[x].substring(0, 4)
                  : backData.timeAxis === 'months'
                  ? backData.days[x].substring(0, 7)
                  : backData.days[x]
              },
              culling: { lines: false, max: 10 }
            },
            label: { text: '期間', position: 'outer-right' }
          },
          y: {
            label: { text: backData.yLabel, position: 'outer-top' },
            min: 0,
            tick: {
              format: function(y) {
                if (!Number.isInteger(y) || y < 0) return ''
                return y < 10000000000
                  ? y.toLocaleString()
                  : new Intl.NumberFormat('ja-JP', {
                      notation: 'compact'
                    }).format(y)
              }
            }
          },
          y2: {
            show: backData.y2ShowFlg,
            label: { text: backData.y2Label, position: 'outer-top' },
            tick: {
              format: function(y) {
                if (!Number.isInteger(y) || y < 0) return ''
                return y < 10000000000
                  ? y.toLocaleString()
                  : new Intl.NumberFormat('ja-JP', {
                      notation: 'compact'
                    }).format(y)
              }
            }
          }
        },
        grid: { x: { show: true }, y: { show: true } },
        size: { height: 300 },
        zoom: { enabled: zoom() }
      })
    },
    createTableData() {
      const columns = this.backData.columns
      const timeAxis = this.backData.timeAxis
      const dates = this.backData.days.map(day => {
        const dateFormat =
          timeAxis === 'days' || timeAxis === 'weeks'
            ? day
            : timeAxis === 'months'
            ? day.substring(0, 7)
            : day.substring(0, 4)
        return dateFormat
      })
      this.tableData.push(dates)
      columns.forEach((column, i) => {
        const arr = _.cloneDeep(column)
        arr.shift()
        this.tableData.push(arr)
      })
      if (this.backData.newOrTotal === 'new') {
        this.tableData.forEach((arr, i) => {
          if (i === 0) {
            this.tableData[0].push('合計', '平均')
          } else {
            const total = arr.reduce((num, val) => {
              return (num += val)
            }, 0)
            const average = Math.round((total / arr.length) * 100) / 100
            this.tableData[i].push(total, average)
          }
        })
      }
    },
    createCsv() {
      const names = Object.values(this.backData.names)
      const headerStr = names.reduce((str, v, i) => {
        str += `,${v}`
        if (names.length === i + 1) str += '\n'
        return str
      }, '期間')
      const dates = this.tableData[0]
      const bodyStr = dates.reduce((str, date, i) => {
        str += date
        this.tableData.forEach((arr, j) => {
          if (j !== 0) str += `,${arr[i]}`
        })
        return (str += '\n')
      }, '')
      const csvStr = headerStr + bodyStr
      const fileName = `csv_line_graph`
      return { csvStr, fileName }
    },
    download() {
      const { csvStr, fileName } = this.createCsv()
      downloadCsv(csvStr, fileName)
    }
  }
}
</script>

<style lang="scss" scoped>
.line-graph {
  width: 100%;
  height: 100%;
  > .body {
    width: 100%;
    height: 100%;
    display: flex;
    > .menu-area {
      width: 345px;
      height: 100%;
    }
    > .graph-area {
      padding-left: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      min-width: 900px;
      > .download-button {
        margin: 10px 0;
        text-align: right;
      }
      > .graph {
        width: 100%;
      }
    }
  }
}
</style>
