<template>
  <div class="line-graph-table">
    <div class="headers">
      <div class="header period">期間</div>
      <div
        class="header variable"
        v-for="(value, key, i) in names"
        :key="key"
        :style="{ color: headerColor(i) }"
        :title="value"
        data-test="header-variable"
      >
        {{ value }}
      </div>
    </div>
    <div class="body">
      <recycle-scroller
        v-if="tableData[0].length > 0"
        class="scroller"
        :items="tableData[0]"
        :item-size="36"
        v-slot="{ item, index }"
      >
        <line-graph-table-row
          class="row"
          :index="index"
          :date="item"
          :tableData="tableData"
        />
      </recycle-scroller>
    </div>
  </div>
</template>

<script>
import LineGraphTableRow from '@/components/parts/organisms/LineGraphTableRow'
import _ from 'lodash'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  name: 'LineGraphTable',

  components: {
    LineGraphTableRow,
    RecycleScroller
  },

  props: {
    names: { type: Object },
    tableData: { type: Array }
  },

  methods: {
    headerColor(index) {
      const colors = { 0: 'red', 1: 'green', 2: 'blue', 3: 'orange' }
      return colors[index] ?? 'purple'
    }
  }
}
</script>

<style lang="scss" scoped>
.line-graph-table {
  max-height: 600px;
  overflow-y: auto;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #{$light-grey};
  > .headers {
    height: 36px;
    background: #{$light-orange};
    width: 100%;
    display: flex;
    > .header {
      box-sizing: border-box;
      overflow: hidden;
      padding: 0 2px;
      &.period {
        width: 100px;
        min-width: 100px;
      }
      &.variable {
        width: calc(100% - 100px);
        border-left: 1px solid #{$light-grey};
      }
    }
  }
}
</style>
