<template>
  <div class="analytics-menu">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="set-up-option wrapper">
        <div class="button-area">
          <div class="label">集計条件</div>
          <base-button-small-orange
            v-if="lookOnlyFlg === 0"
            class="save-button"
            :styles="{ width: '50px' }"
            @click="openTextBoxPopup"
            :disabled="invalid"
            data-test="save-button-analytics"
            >保存</base-button-small-orange
          >
          <base-button-small-red
            v-if="lookOnlyFlg === 0"
            class="del-button"
            :styles="{ width: '50px' }"
            @click="openDeletePopup"
            :disabled="selectAnalyticsId === 0"
            data-test="delete-button-analytics"
            >削除</base-button-small-red
          >
        </div>
        <div class="input-area">
          <base-select-box
            class="select-box"
            v-model="selectAnalyticsId"
            :styles="{ width: '240px' }"
            :selectData="analyticsSelectData"
            @input="setAnalytics(selectAnalyticsId)"
            data-test="select-box-analytics"
          />
          <base-button-small-white
            :styles="{ width: '81px', marginLeft: '10px' }"
            @click="setAnalytics(0)"
            data-test="clear-button-analytics"
            >クリア</base-button-small-white
          >
        </div>
      </div>
      <div class="period wrapper">
        <div class="label">期間</div>
        <div class="input-area">
          <v-date-picker
            v-model="startDate"
            :input-props="{
              class: 'v-date-picker start',
              readonly: true
            }"
            @input="
              date => (conditions.startDate = moment(date).format('YYYYMMDD'))
            "
            :min-date="new Date(2020, 0, 1)"
            :max-date="maxDate"
            is-required
            data-test="v-date-picker-start"
          />
          <div class="wavy-line">～</div>
          <v-date-picker
            v-model="endDate"
            :input-props="{
              class: 'v-date-picker end',
              readonly: true
            }"
            @input="
              date => (conditions.endDate = moment(date).format('YYYYMMDD'))
            "
            :min-date="minDate"
            :maxDate="nextYear"
            is-required
            data-test="v-date-picker-end"
          />
        </div>
      </div>
      <div class="time-axis wrapper">
        <div class="label">単位</div>
        <div class="input-area">
          <base-radio-button
            v-for="option in timeAxisOptions"
            :key="option.id"
            v-model="conditions.timeAxis"
            :option="option"
            :styles="{ marginRight: '15px', fontSize: '13px' }"
            :inputStyles="{ paddingLeft: '15px' }"
          />
        </div>
      </div>
      <div class="new-or-total wrapper">
        <div class="label">新規・累計</div>
        <div class="input-area">
          <base-radio-button
            v-for="option in newOrTotalOptions"
            :key="option.id"
            v-model="conditions.newOrTotal"
            :option="option"
            :styles="{ marginRight: '15px', fontSize: '13px' }"
            :inputStyles="{ paddingLeft: '15px' }"
          />
        </div>
      </div>
      <div
        class="target wrapper"
        v-for="(target, tIndex) in conditions.targets"
        :key="target.key"
      >
        <div class="label-area">
          <div class="label" :style="targetLabelStyle(tIndex)">
            集計対象 {{ tIndex + 1 }}
          </div>
          <validation-provider
            class="validation-provider"
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-text-box
              v-model="target.label"
              :maxlength="10"
              :placeholder="'集計対象名'"
              :styles="{ width: '217px', fontSize: '15px' }"
              data-test="label-input-box"
            />
            <div class="error" data-test="err">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="target-select-box-area">
          <validation-provider
            :rules="{ requiredRule: true }"
            v-slot="{ errors }"
          >
            <base-select-box-no-id
              v-model="target.name"
              :selectData="targetsSelectData"
              :styles="{ width: '295px' }"
              data-test="select-box-target"
            />
            <div class="error" data-test="err">{{ errors[0] }}</div>
          </validation-provider>
          <base-button-trash
            v-if="tIndex !== 0"
            class="trash-button"
            @click="delTarget(target.key)"
          />
        </div>
        <div class="filter-area" v-if="target.name">
          <div class="label">絞り込み</div>
          <div
            class="filter-input-area"
            v-for="(filter, fIndex) in target.filters"
            :key="filter.key"
          >
            <validation-provider
              class="validation-provider"
              :rules="{ requiredRule: true }"
              v-slot="{ errors }"
            >
              <!-- バリデーションエラーを上手く作動させるために@input="filter.id = null"を使用してます。無い場合の不具合例 1, スタッフで誰かを選ぶ => 種別に変更する => その種別に無いIDが選ばれた状態でバリエーションが効いてない状態ができる -->
              <base-select-box-no-id
                v-model="filter.name"
                class="filter-names-select-box"
                :selectData="
                  filterNamesSelectData(target.filters, fIndex, target.name)
                "
                :styles="{ width: '90px' }"
                @input="filter.id = null"
                data-test="filter-names-select-box"
              />
              <div class="error adjust" data-test="err">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider
              v-if="filter.name !== 'breed'"
              class="validation-provider"
              :rules="{ requiredRule: true }"
              v-slot="{ errors }"
            >
              <base-select-box
                v-model="filter.id"
                class="filters-select-box"
                :selectData="filtersSelectData(filter.name)"
                :styles="{ width: '160px' }"
                data-test="filters-select-box"
              />
              <div class="error adjust2" data-test="err">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider
              v-if="filter.name === 'breed'"
              class="validation-provider"
              :rules="{ notSurrogatePair: true }"
              v-slot="{ errors }"
            >
              <base-text-box
                v-model="filter.text"
                class="text-box"
                :styles="{ width: '160px' }"
                :maxlength="50"
              />
              <div class="error adjust2" data-test="err">{{ errors[0] }}</div>
            </validation-provider>
            <base-button-trash
              class="trash-button"
              @click="delFilter(tIndex, filter.key)"
              data-test="trash-button-filter"
            />
          </div>
          <base-button-plus
            class="plus-button"
            :styles="{ width: '35px' }"
            :disabled="
              target.filters.length >= 6 ||
                (target.name === 'newOwner' && target.filters.length >= 3)
            "
            @click="addFilter(tIndex)"
            data-test="filter-plus-button"
          />
        </div>
      </div>
      <div class="buttons wrapper">
        <base-button-plus
          class="add-button"
          :styles="{ width: '140px' }"
          :text="'集計対象追加'"
          :disabled="conditions.targets.length >= 5"
          @click="addTarget"
          data-test="plus-button-target"
        />
        <base-button-medium-orange
          class="start-button"
          :disabled="analyticsWaitFlg || invalid"
          @click="startAnalytics"
          data-test="start-analytics"
          >集計開始</base-button-medium-orange
        >
      </div>
    </validation-observer>

    <announce-popup
      v-if="popup.alertFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      :layerNumber="3"
      @cancel="popup.alertFlg = false"
      @decision="popup.decision"
      @close="closePopup"
      >{{ popup.message }}</announce-popup
    >
    <text-box-popup
      v-if="textBoxPopup.alertFlg"
      ref="textBoxPopup"
      :value="textBoxPopup.value"
      :buttons="textBoxPopup.buttons"
      :names="textBoxPopup.names"
      :waitFlg="waitFlg"
      @input="value => (textBoxPopup.value = value)"
      @close="textBoxPopup.alertFlg = false"
      @decision="saveConditions"
      >{{ textBoxPopup.message }}</text-box-popup
    >
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseSelectBoxNoId from '@/components/parts/atoms/BaseSelectBoxNoId'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import TextBoxPopup from '@/components/popups/TextBoxPopup'

export default {
  name: 'LineGraphMenu',

  components: {
    BaseSelectBox,
    BaseTextBox,
    BaseButtonSmallWhite,
    BaseRadioButton,
    BaseSelectBoxNoId,
    BaseButtonTrash,
    BaseButtonPlus,
    BaseButtonSmallOrange,
    BaseButtonMediumOrange,
    BaseButtonSmallRed,
    ValidationObserver,
    ValidationProvider,
    AnnouncePopup,
    TextBoxPopup
  },

  mixins: [CheckInputDifference],

  props: {
    analyticsWaitFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      selectAnalyticsId: 0,
      conditions: {},
      startDate: null,
      endDate: null,
      moment: moment,
      timeAxisOptions: [
        { id: 1, eachValue: 'days', labelName: '日' },
        { id: 2, eachValue: 'weeks', labelName: '週' },
        { id: 3, eachValue: 'months', labelName: '月' },
        { id: 4, eachValue: 'years', labelName: '年' }
      ],
      newOrTotalOptions: [
        { id: 5, eachValue: 'new', labelName: '新規' },
        { id: 6, eachValue: 'total', labelName: '累計' }
      ],
      targetsSelectData: [
        ['予約数', 'reservation'],
        ['カルテ数', 'karte'],
        ['売上(診療明細)', 'medicalPaymentSales'],
        ['売上(会計済み)', 'paymentSales'],
        ['登録飼主数', 'newOwner'],
        ['保険金請求額', 'insurancePrice'],
        ['未収金', 'unpaid']
      ],
      popup: {
        alertFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        decision: () => {}
      },
      waitFlg: false,
      textBoxPopup: {
        alertFlg: false,
        message: '',
        value: '',
        buttons: [],
        names: []
      },
      keepFlg: false
    }
  },

  computed: {
    ...mapGetters({
      analytics: 'analytics/getData',
      analyticsSelectData: 'analytics/selectDataZero',
      getAnalytics: 'analytics/getDataById',
      staffsSelectData: 'staffs/selectData',
      speciesSelectData: 'species/selectData',
      reservationPurposesSelectData: 'reservationPurposes/selectData',
      reservationColumnsSelectData: 'reservationColumns/selectData',
      ownerTagsSelectData: 'ownerTags/selectData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    staffsSelectDataZero() {
      return [{ id: 0, name: '未設定' }].concat(this.staffsSelectData)
    },
    reservationPurposesSelectDataZero() {
      return [{ id: 0, name: '未設定' }].concat(
        this.reservationPurposesSelectData
      )
    },
    ownerTagsSelectDataZero() {
      return [{ id: 0, name: '未設定' }].concat(this.ownerTagsSelectData)
    },
    analyticsNames() {
      return this.analytics.map(v => v.name)
    },
    maxDate() {
      const endDate = this.conditions.endDate
      return endDate === '' ? null : moment(endDate, 'YYYYMMDD').toDate()
    },
    minDate() {
      const startDate = this.conditions.startDate
      return startDate === '' ? null : moment(startDate, 'YYYYMMDD').toDate()
    },
    nextYear() {
      const today = new Date()
      return new Date(today.getFullYear() + 1, 11, 31)
    },
    filterNamesSelectData: function() {
      return function(filters, fIndex, targetName) {
        const usedElsewhereFilterNames = filters
          .filter((_, i) => i !== fIndex)
          .map(v => v.name)
        return targetName !== 'newOwner'
          ? [
              ['スタッフ', 'staff'],
              ['種別', 'species'],
              ['品種', 'breed'],
              ['来院目的', 'reservationPurpose'],
              ['予約列', 'reservationColumn'],
              ['飼主タグ', 'ownerTag']
            ].filter(v => !usedElsewhereFilterNames.includes(v[1]))
          : [
              ['種別', 'species'],
              ['品種', 'breed'],
              ['飼主タグ', 'ownerTag']
            ].filter(v => !usedElsewhereFilterNames.includes(v[1]))
      }
    },
    filtersSelectData: function() {
      return function(name) {
        return name === 'staff'
          ? this.staffsSelectDataZero
          : name === 'species'
          ? this.speciesSelectData
          : name === 'reservationPurpose'
          ? this.reservationPurposesSelectDataZero
          : name === 'reservationColumn'
          ? this.reservationColumnsSelectData
          : name === 'ownerTag'
          ? this.ownerTagsSelectDataZero
          : []
      }
    },
    targetLabelStyle: function() {
      return function(tIndex) {
        return tIndex === 0
          ? { color: 'red' }
          : tIndex === 1
          ? { color: 'green' }
          : tIndex === 2
          ? { color: 'blue' }
          : tIndex === 3
          ? { color: 'orange' }
          : { color: 'purple' }
      }
    }
  },

  created() {
    this.setUpInitialConditions()
    this.setUpInitialDate()
    this.setUpInitialData()
  },

  methods: {
    setUpInitialConditions() {
      this.conditions = {
        startDate: moment()
          .startOf('month')
          .format('YYYYMMDD'),
        endDate: moment()
          .endOf('month')
          .format('YYYYMMDD'),
        timeAxis: 'days',
        newOrTotal: 'new',
        targets: [{ key: 1, label: '', name: '', filters: [] }]
      }
    },
    setUpInitialDate() {
      const day = new Date()
      day.setDate(1)
      this.startDate = _.cloneDeep(day)
      day.setMonth(day.getMonth() + 1)
      day.setDate(0)
      this.endDate = _.cloneDeep(day)
    },
    setUpInitialData() {
      this.mixinInitialData = {
        selectAnalyticsId: _.cloneDeep(this.selectAnalyticsId),
        conditions: _.cloneDeep(this.conditions)
      }
      this.mixinInputData = {
        selectAnalyticsId: this.selectAnalyticsId,
        conditions: this.conditions
      }
    },
    setAnalytics(arg) {
      const analytics = typeof arg === 'number' ? this.getAnalytics(arg) : arg
      if (analytics) {
        const conditions = _.cloneDeep(analytics.conditions)
        this.startDate = moment(conditions.startDate, 'YYYYMMDD').toDate()
        this.endDate = moment(conditions.endDate, 'YYYYMMDD').toDate()
        this.conditions = conditions
        this.selectAnalyticsId = analytics.id
      } else {
        this.setUpInitialConditions()
        this.setUpInitialDate()
        this.selectAnalyticsId = 0
      }
      this.setUpInitialData()
    },
    addTarget() {
      const lastKey = this.conditions.targets[
        this.conditions.targets.length - 1
      ].key
      this.conditions.targets.push({
        key: lastKey + 1,
        label: '',
        name: '',
        filters: []
      })
    },
    delTarget(targetKey) {
      this.conditions.targets = this.conditions.targets.filter(
        v => v.key !== targetKey
      )
    },
    addFilter(tIndex) {
      const lastKey =
        this.conditions.targets[tIndex].filters.length === 0
          ? 0
          : this.conditions.targets[tIndex].filters[
              this.conditions.targets[tIndex].filters.length - 1
            ].key
      this.conditions.targets[tIndex].filters.push({
        key: lastKey + 1,
        id: null,
        name: '',
        text: ''
      })
    },
    delFilter(tIndex, filterKey) {
      this.conditions.targets[tIndex].filters = this.conditions.targets[
        tIndex
      ].filters.filter(v => v.key !== filterKey)
    },
    openTextBoxPopup() {
      this.textBoxPopup =
        this.selectAnalyticsId === 0
          ? {
              alertFlg: true,
              message: '集計条件名を入力し「作成する」ボタンを押して下さい。',
              value: '',
              buttons: ['作成しない', '作成する'],
              names: this.analyticsNames
            }
          : {
              alertFlg: true,
              message: '集計条件名を入力し「編集する」ボタンを押して下さい。',
              value: this.getAnalytics(this.selectAnalyticsId).name,
              buttons: ['編集しない', '編集する'],
              names: this.analyticsNames.filter(
                v => v !== this.getAnalytics(this.selectAnalyticsId).name
              )
            }
    },
    saveConditions() {
      this.selectAnalyticsId === 0
        ? this.createConditions()
        : this.updateConditions()
    },
    async createConditions() {
      this.waitFlg = true
      const analytics = {
        name: this.textBoxPopup.value,
        conditions: JSON.stringify(this.conditions)
      }
      const res = await this.$store.dispatch('analytics/create', analytics)
      if (res.result === true) {
        this.$refs.textBoxPopup.setInitialValue()
        this.openSuccessPopup('集計条件を登録しました。')
        this.setAnalytics(res.analytics)
      } else {
        res === 'already used'
          ? this.openFailurePopup('既に同じ名前のデータが存在します。')
          : this.openFailurePopup('登録に失敗しました。')
        if (res === 'already used') this.keepFlg = true
      }
      this.waitFlg = false
    },
    async updateConditions() {
      this.waitFlg = true
      const analytics = {
        id: this.selectAnalyticsId,
        name: this.textBoxPopup.value,
        conditions: JSON.stringify(this.conditions)
      }
      const res = await this.$store.dispatch('analytics/update', analytics)
      if (res.result === true) {
        this.$refs.textBoxPopup.setInitialValue()
        this.openSuccessPopup('集計条件を編集しました。')
        this.setAnalytics(res.analytics)
      } else {
        if (res === 'no data in clinic') {
          this.setUpInitialConditions()
          this.setUpInitialDate()
          this.selectAnalyticsId = 0
          this.setUpInitialData()
        }
        res === 'no data in clinic'
          ? this.openFailurePopup('存在しないデータです。')
          : res === 'no data'
          ? this.openFailurePopup('既に削除されています。')
          : res === 'already used'
          ? this.openFailurePopup('既に同じ名前のデータが存在します。')
          : this.openFailurePopup('編集に失敗しました。')
        if (res === 'already used') this.keepFlg = true
      }
      this.waitFlg = false
    },
    openDeletePopup() {
      this.popup = {
        alertFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        message: '集計条件を削除してもよろしいですか？',
        decision: () => this.deleteConditions()
      }
    },
    async deleteConditions() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'analytics/delete',
        this.selectAnalyticsId
      )
      if (res === true) {
        this.setUpInitialConditions()
        this.setUpInitialDate()
        this.setUpInitialData()
        this.openSuccessPopup('集計条件を削除しました。')
        this.setAnalytics(0)
      } else {
        if (res === 'no data in clinic') {
          this.setUpInitialConditions()
          this.setUpInitialDate()
          this.selectAnalyticsId = 0
          this.setUpInitialData()
        }
        res === 'no data in clinic'
          ? this.openFailurePopup('存在しないデータです。')
          : this.openFailurePopup('削除に失敗しました。')
      }
      this.waitFlg = false
    },
    openSuccessPopup(message) {
      this.popup = {
        alertFlg: true,
        type: 'success',
        title: '成功',
        buttons: ['閉じる'],
        message,
        decision: () => {}
      }
    },
    openFailurePopup(message) {
      this.popup = {
        alertFlg: true,
        type: 'failure',
        title: '失敗',
        buttons: ['閉じる'],
        message,
        decision: () => {}
      }
    },
    startAnalytics() {
      this.setUpInitialData()
      this.$emit('click', this.conditions)
    },
    closePopup() {
      this.popup.alertFlg = false
      if (!this.keepFlg) {
        this.textBoxPopup.alertFlg = false
      } else {
        this.keepFlg = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.analytics-menu {
  width: 100%;
  min-height: calc(100vh - 163px);
  padding: 30px 6px 0px 6px;
  box-sizing: border-box;
  border-right: 1px solid #{$light-grey};
  > .validation-observer {
    > .wrapper {
      margin-bottom: 20px;
      text-align: left;
      .label {
        line-height: 33px;
        margin-right: 5px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
      > .label-area {
        display: flex;
        margin-bottom: 20px;
        .label {
          line-height: 33px;
          margin-right: 5px;
          font-size: 15px;
          font-weight: bold;
          text-align: left;
        }
        @include validate-message();
      }
      .input-area {
        display: flex;
      }
    }
    > .set-up-option {
      > .button-area {
        display: flex;
        > .label {
          line-height: 33px;
        }
        > .save-button {
          margin: -10px 10px 0 auto;
        }
        > .del-button {
          margin-top: -10px;
        }
      }
    }
    > .period {
      > .input-area {
        ::v-deep {
          > span {
            .v-date-picker {
              font-size: 13px;
              text-align: right;
              padding-right: 12px;
              width: 140px;
              height: 33px;
              border-radius: 5px;
              border: solid 2px #{$light-grey};
              text-indent: 5px;
              cursor: pointer;
              background-image: url('../../../assets/images/calendar.png');
              background-size: 18px 18px;
              background-repeat: no-repeat;
              background-position: 5px center;
            }
          }
        }
        > .wavy-line {
          line-height: 33px;
          margin: 0 10px;
        }
      }
    }
    > .target {
      > .target-select-box-area {
        display: flex;
        @include validate-message();
        > .trash-button {
          margin-left: 5px;
        }
      }
      > .trash-button {
        margin-left: 5px;
      }
      > .filter-area {
        > .label {
          font-size: 13px;
          margin: 10px 0 0 40px;
        }
        > .filter-input-area {
          display: flex;
          @include validate-message();
          > .validation-provider {
            > .adjust {
              margin-top: -18px;
              margin-left: 40px;
            }
            > .adjust2 {
              margin-top: -3px;
              margin-left: 5px;
            }
            > .filter-names-select-box {
              margin-bottom: 15px;
              padding-left: 40px;
            }
            > .filters-select-box {
              margin-left: 5px;
            }
            > .text-box {
              margin-left: 5px;
            }
          }
          > .trash-button {
            margin-left: 5px;
          }
        }
        > .plus-button {
          margin-left: 40px;
        }
      }
    }
    > .buttons {
      > .start-button {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
</style>
